// helpers/orderLineItems
import vectorIdentifier from './vectorIdentifier'


export function orderLineItems(product, order, shipping = {Province: "ON"}) {
  const province = shipping.Province

  function calculateDigitizationFee(_obj) {

    var fee = 0;

    if ((_obj.Front.Type === 'LOGO') || (_obj.Back.Type === 'LOGO')) {

      if (!vectorIdentifier(_obj.Front.logoName) && _obj.Front.Type === 'LOGO') {
        fee = 65;
      }
      if (!vectorIdentifier(_obj.Back.logoName) && _obj.Back.Type === 'LOGO') {
        fee = fee + 65;
        if (_obj.Front.logo === _obj.Back.logo) {
          fee = fee - 65;
        }
      }

    } 

    return fee;

  }


  const taxRates = {
    AB: .05,
    BC: .12,
    MB: .12,
    NB: .15,
    NL: .15,
    NT: .05,
    NS: .15,
    NU: .05,
    ON: .13,
    PE: .15,
    QC: .14975,
    SK: .11,
    YT: .05,
  }

  var productSub = order.Quantity * (product.Price - product.Discount)
  var frontCostPerDozen = order.Quantity < 12 ? product.CustCostFront1to11 : (order.Quantity >= 12) && (order.Quantity < 24) ? product.CustCostFront12to23 : ((order.Quantity >= 24) && (order.Quantity < 48)) ? product.CustCostFront24to47 : ((order.Quantity >= 48) && (order.Quantity < 119)) ? product.CustCostFront48to119 : product.CustCostFrontOver120;
  var backCostPerDozen = order.Quantity < 12 ? product.CustCostBack1to11 : (order.Quantity >= 12) && (order.Quantity < 24) ? product.CustCostBack12to23 : ((order.Quantity >= 24) && (order.Quantity < 48)) ? product.CustCostBack24to47 : ((order.Quantity >= 48) && (order.Quantity < 119)) ? product.CustCostBack48to119 : product.CustCostBackOver120;
  var frontSub = order['Front'].Type != 'NONE' ? frontCostPerDozen * order.Quantity : 0;
  var backSub = order['Back'].Type != 'NONE' ? backCostPerDozen * order.Quantity : 0;
  var digitizationfee = calculateDigitizationFee(order);
  var beforeShippingAndTaxes = productSub + frontSub + backSub + digitizationfee
  var shipping = 0
  var taxes = (beforeShippingAndTaxes + shipping) * taxRates[province]
  var total = beforeShippingAndTaxes + shipping + taxes


  return ({
    Balls: {
      Per: product.Price - product.Discount,
      SubTotal: productSub,
    },
    Front: {
      Per: order['Front'].Type != 'NONE' ? frontCostPerDozen : 0,
      SubTotal: frontSub
    },
    Back: {
      Per: order['Back'].Type != 'NONE' ? backCostPerDozen : 0,
      SubTotal: backSub
    },
    DigitizationFee: digitizationfee,
    BeforeShippingAndTaxes: beforeShippingAndTaxes,
    Taxes: taxes,
    Shipping: shipping,
    Total: total
  });
}


