// Order Reducer

import { 
  DELETE_FACE,
  SET_PRODUCT,
  SET_CURRENTLY_EDITING,
  SET_BALL_TEXTS,
  LOCK_IN_TEXT,
  SET_LOGO_AND_LOGO_NAME,
  RESET_LOGO_AND_LOGO_NAME_FOR_FACE,
  SET_COLOR,
  SET_CROPPED_LOGO_SRC,
  SET_SAME_AS_FRONT,
  SET_QUANTITY,
  SET_VECTOR_LOGO_DETAILS_FOR_FACE,
  RESET_VECTOR_LOGO_DETAILS_FOR_FACE,
  SET_ORDER_ID,
  SET_BAMBORA_ID,
  ORDER_RESET_FOR_REPEAT
} from './order.types';



const INITIAL_STATE = {
    product: {},
    Quantity: 24,
    currentlyEditing: 'Front', //'front', 'back'
    Front: {
        Type: 'NONE', //'NONE','LOGO','TEXT'
        Line1: '',
        Line2: '',
        Line3: '',
        Color: 'black',
        logo: '',
        logoName: '',
        src: '',
        Notes: ''
    },
    Back: {
        Type: 'NONE', //'NONE','LOGO','TEXT'
        Line1: '',
        Line2: '',
        Line3: '',
        Color: 'black',
        logo: '',
        logoName: '',
        src: '',
        Notes: ''
    },
};

const orderReducer = (state = INITIAL_STATE, action) => {

    let updatedState = JSON.parse(JSON.stringify(state));

    switch (action.type) {

        case SET_PRODUCT:

            const newQuantity = updatedState.Quantity < action.payload.MinimumQuantity ? action.payload.MinimumQuantity : updatedState.Quantity;

            return {
                ...updatedState,
                product: action.payload,
                Quantity: newQuantity
            };

        break;

        case SET_BALL_TEXTS:

            if (action.payload.target.value.length < 15) {
              updatedState[state.currentlyEditing] = {
                  ...state[state.currentlyEditing],
                  [action.payload.target.name]: action.payload.target.value
              }
            }

            return {
                ...updatedState,
            };

        break;


        case LOCK_IN_TEXT:

              updatedState[state.currentlyEditing].Type = 'TEXT';

            return {
                ...updatedState,
            };

        break;


        case DELETE_FACE:

            if (action.payload === 'Front') {
                updatedState['Front'] = { ...state.Back }
                if (updatedState['Back'].Type === 'NONE') {
                    updatedState['currentlyEditing'] = 'Front'
                }
            }

            updatedState['Back'] = { ...INITIAL_STATE.Back }

            return {...updatedState};

        break;



        case SET_CURRENTLY_EDITING:

            return {
                ...updatedState,
                currentlyEditing: action.payload,
            };

        break;



        case DELETE_FACE:

            if (state.currentlyEditing === 'Front') {
                updatedState['Front'] = { ...state.back }
            }

            updatedState['Back'] = { ...INITIAL_STATE.back }

            return {...updatedState};

        break;



        case RESET_LOGO_AND_LOGO_NAME_FOR_FACE:

            updatedState[action.payload] = { 
                ...state[action.payload],
                Type: "NONE",
                logo: "",
                logoName: "",
                src: "",
            }

            return {...updatedState};

        break;


        case SET_COLOR:

            updatedState[state.currentlyEditing].Color = action.payload;

            return {...updatedState};

        break;


        case SET_LOGO_AND_LOGO_NAME:

            updatedState[state.currentlyEditing] = {
                ...updatedState[state.currentlyEditing],
                logoName: action.payload.logoName,
                logo: action.payload.logo
            };

            return { ...updatedState };

        break;


        case SET_CROPPED_LOGO_SRC:

            updatedState[state.currentlyEditing].Type = 'LOGO';
            updatedState[state.currentlyEditing].src = action.payload.src;
            updatedState[state.currentlyEditing].Notes = action.payload.notes;

            return { ...updatedState };

        break;


        case SET_SAME_AS_FRONT:

            updatedState.Back = {...updatedState.Front};

            return { ...updatedState };

        break;


        case SET_QUANTITY:

            var quantity = state.Quantity

            if (action.payload >= updatedState.product.MinimumQuantity) {
                quantity = action.payload
            }

            return { ...state,
            Quantity: quantity };

        break;


        case SET_VECTOR_LOGO_DETAILS_FOR_FACE:

            updatedState[state.currentlyEditing] = {
                ...updatedState[state.currentlyEditing],
                ...action.payload
            }

            return { 
                ...updatedState 
            };

        break;


        case RESET_VECTOR_LOGO_DETAILS_FOR_FACE:

            updatedState[action.payload.face] = {
                ...updatedState[action.payload.face],
                ID: '',
                PngURL: '',
                VectorURL: '',
                CroppedURL: ''
            }

            return { 
                ...updatedState 
            };

        break;


        case SET_ORDER_ID:

            updatedState.ID = action.payload;

            return {...updatedState};

        break;


        case SET_BAMBORA_ID:

            updatedState.BamboraID = action.payload;

            return {...updatedState};

        break;

        case ORDER_RESET_FOR_REPEAT:

            updatedState.BamboraID = "";
            updatedState.ID = "";

            return {...updatedState};

        break;


        default: return state;

    }

};



export default orderReducer;