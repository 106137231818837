import React from 'react' 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../helpers/currencyFormatter'
import './Products.scss';

function ModalProduct(props) {
    const { events, changeSelectedProduct, product } = props;
    const { t, i18n } = useTranslation();
    const curLanguage = i18n.language.toUpperCase();

    return (
        <div className='col-6'>
            <div className='modal-product product-item'>
                <img src={`${product[`BoxImage${curLanguage}`]}`} alt="" />
                <h4>{product.BrandName}</h4>
                <h3>{product[`Name${curLanguage}`]}</h3>
                    	<span className={`ps-1 pt-1 ${product.Discount > 0 ? 'discountred' : null}`}> 
	                      {currencyFormatter(i18n).format( product.Price - product.Discount )} 
	                      {product.Discount > 0 ?
  	                      <span className='discountregprice'>&nbsp;({t('reg. price')} {currencyFormatter(i18n).format(product.Price)})</span>
  	                      :
  	                      <span className='text-green-2'>&nbsp;+ {t('Customization')}</span>}
                      </span> 
                {/*product.CustCostFront12to23 === 0 ? <div className="free-customization-snipe">FREE<br/>CUSTOMIZATION</div> : null*/}
                { product.Discount > 0 ? <div className="hotdeal-snipe"><h2 className="deal">{t('HOT DEAL!')}</h2><span className="qualifier">{t('FREE CUSTOMIZATION*')}</span><span className="legal">{t('*Minimum Order 24')}</span></div> : null }
                <button className='button red-button' onClick={() => {
                    events.toggleproductChangeModal();
                    changeSelectedProduct(product);
                }}>
                {t('Select Ball')}
                </button>
            </div>
        </div>
    )
}

export default ModalProduct