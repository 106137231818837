import './ChangeBallBox.scss'
import { connect, useDispatch   } from "react-redux";
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../helpers/currencyFormatter'

const mapStateToProps = (state) => ({
  currentItem: state.order.product,
})


function ChangeBallBox(props) {

    const { currentItem, events: { toggleproductChangeModal, toggleProductPriceModal } } = props;
    const { t, i18n } = useTranslation();
    const curLanguage = i18n.language.toUpperCase();

    return (
        <div className="change-ball-box">
            <div className='d-flex justify-content-between align-items-center mb-3 mb-md-0'>
                <img src={`${currentItem[`BoxImage${curLanguage}`]}`} alt="" />
                <div>
                    <div className='pe-4 me-1'>
                        <div>
                            <h4 className='text-bold text-uppercase'>{currentItem.BrandName}</h4>
                            <h3>{currentItem[`Name${curLanguage}`]}</h3>
                            <button onClick={()=>toggleProductPriceModal(currentItem)} className="price-info text-start d-flex text-black align-items-center">
                                <img className="me-1 me-sm-2" src="/static/media/info.0c6d1e1172ed5de6ca0e951254f335d7.svg" alt="" />
					                    	<span className={`ps-1 pt-1 ${currentItem.Discount > 0 ? 'discountred' : null}`}> 
						                      {currencyFormatter(i18n).format( currentItem.Price - currentItem.Discount )} 
						                      {currentItem.Discount > 0 ?
					  	                      <span className='discountregprice'>&nbsp;(reg. price ${currentItem.Price})</span>
					  	                      :
					  	                      <span className='text-green-2'>&nbsp;+ {t('Customization')}</span>}
					                      </span> 
                            </button>
                        </div>
                    </div>
                    <div className="text-end d-none d-lg-block mt-3">
                        <button onClick={toggleproductChangeModal} className="button">{t('Change')}</button>
                    </div>
                </div>
            </div>
            <div className="text-center d-lg-none">
                <button onClick={toggleproductChangeModal} className="button">{t('Change')}</button>
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(ChangeBallBox)